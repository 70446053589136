<template>

  <div>

    <!--
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewcarsidebarActive"
      @refetch-data="refetchData"
    />
    -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge anzeigen</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="vinList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Keine passenden Einträge gefunden"
        style="min-height:200px"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(avatar)="data">
          <b-avatar
            :text="avatarText(fullName(data.item.lastName,data.item.firstName))"
            variant="success"
          />
          <b-link
            :to="{ name: 'user-detail', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          />
        </template>

        <template #cell(socketType)="data">
          <b-avatar
            v-if="data.item.socketType == 1"
            :text="data.item.socketType"
            variant="success"
          />
          <b-avatar
            v-if="data.item.socketType == 2"
            :text="data.item.socketType"
            variant="primary"
          />
        </template>

        <template #cell(street)="data">
          {{ data.value }}<br>
          {{ data.item.postNr }} {{ data.item.city }}
        </template>

        <template #cell(customerType)="data">
          <div v-if="data.item.customerType == 30">
            <b-badge
              pill
              variant="light-primary"
            >
              <feather-icon
                icon="ZapIcon"
                size="19"
              />
            </b-badge>
            <b-badge
              pill
              variant="light-info"
            >
              <feather-icon
                icon="DollarSignIcon"
                size="19"
              />
            </b-badge>
          </div>
          <div v-if="data.item.customerType == 10">
            <b-badge
              pill
              variant="light-primary"
            >
              <feather-icon
                icon="ZapIcon"
                size="19"
              />
            </b-badge>
          </div>
          <div v-if="data.item.customerType == 20">
            <b-badge
              pill
              variant="light-info"
            >
              <feather-icon
                icon="DollarSignIcon"
                size="19"
              />
            </b-badge>
          </div>
        </template>

        <template #cell(lastName)="data">
          <b-link
            :to="{ name: 'user-detail', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.lastName }} {{ data.item.firstName }}
          </b-link>
        </template>

        <template #cell(t0)="data">
          {{ data.item.t0 | moment("DD.MM. YYYY") }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'user-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Mehr</span>
            </b-dropdown-item>

            <!--
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
            -->
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} bis {{ dataMeta.to }} von {{ dataMeta.of }} Einträge</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCars"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink,
  BDropdown, BDropdownItem, BPagination, BAvatar, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
  computed,
} from '@vue/composition-api'
// . import { avatarText } from '@core/utils/filter'
import vin from '../../mock/vin.json'
import store from '@/store'
import finStoreModule from '../finStoreModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,
    BAvatar,
    BBadge,

    vSelect,
  },
  methods: {
    filterTypeClass() {
      let style = 'd-flex align-items-center justify-content-end mb-1 mb-md-0'
      if ((this.$store.getters['app/currentBreakPoint'] === 'xs') || (this.$store.getters['app/currentBreakPoint'] === 'sm')) {
        style = 'd-flex align-items-center justify-content-start mb-1 mb-md-0'
      }

      return style
    },
  },
  setup() {
    const FIN_APP_STORE_MODULE_NAME = 'app-fin'

    // Register module
    if (!store.hasModule(FIN_APP_STORE_MODULE_NAME)) store.registerModule(FIN_APP_STORE_MODULE_NAME, finStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FIN_APP_STORE_MODULE_NAME)) store.unregisterModule(FIN_APP_STORE_MODULE_NAME)
    })

    // . sidebar trigger
    const isAddNewcarsidebarActive = ref(false)
    const perPage = ref(25)
    const totalCars = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('t0')
    const isSortDirDesc = ref(true)

    const refUserListTable = ref(null)

    const tableColumns = [
      { key: 'vin', label: 'FIN' },
      { key: 'socketType', label: 'Typ', thStyle: { width: '80px !important' } },
      { key: 'email', label: 'Kunde' },
      { key: 'street', label: 'Adresse' },
      { key: 't0', label: 'Erstellt am', thStyle: { width: '150px !important' } },
      { key: 'actions', label: 'Aktionen', thStyle: { width: '80px !important' } },
    ]

    totalCars.value = 50
    const vinList = vin

    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCars.value,
      }
    })

    /*
    const fetchCars = (ctx, callback) => {
      store
        .dispatch('app-fin/fetchCars', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
        })
        .then(response => {
          const total = response.data.records
          const cars = response.data.payload

          callback(cars)
          totalCars.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching FIN list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const refetchData = () => {
      refUserListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery], () => {
      refetchData()
    })
    */

    return {
      // Sidebar
      isAddNewcarsidebarActive,

      tableColumns,
      perPage,
      currentPage,
      totalCars,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      vinList,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
